import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OnboardingState } from './types/onboarding'
import { OnboardingType } from './types/onboarding-type'

const initialState: OnboardingState = {
  workspaceName: '',
  allowJoinByDomain: false,
  workspaceId: '',
  onboardingType: OnboardingType.WS,
  firstName: '',
  lastName: '',
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    addWorkspaceName: (state, action: PayloadAction<string>) => {
      state.workspaceName = action.payload
    },
    addFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload
    },
    addLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload
    },
    setAllowJoinByDomain: (state, action: PayloadAction<boolean>) => {
      state.allowJoinByDomain = action.payload
    },
    addWorkspaceId: (state, action: PayloadAction<string>) => {
      state.workspaceId = action.payload
    },
    setOnboardingType: (state, action: PayloadAction<OnboardingType>) => {
      state.onboardingType = action.payload
    },
    resetOnboarding: () => initialState,
  },
})

export const {
  addWorkspaceName,
  addWorkspaceId,
  setAllowJoinByDomain,
  setOnboardingType,
  resetOnboarding,
  addFirstName,
  addLastName,
} = onboardingSlice.actions

export default onboardingSlice.reducer
