import { Conversation } from '../conversation'

export const parseConversationUserLeft = (conversation: Conversation, userId: string) => {
  return conversation.chatUsers.map((chatUser) => {
    const { user, leftChat } = chatUser

    if (user.userId === userId) {
      return { ...chatUser, leftChat: !leftChat }
    }

    return chatUser
  })
}
