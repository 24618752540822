import { createEntityAdapter, createSelector, createSlice, EntityState } from '@reduxjs/toolkit'
import { usersEntityAdapter, usersInitialState } from './entity-adapter'
import { User } from './user-type'

const usersAdapter = createEntityAdapter<User>()

export const { selectAll: selectUsers, selectById: selectUser } = usersAdapter.getSelectors(
  (state: EntityState<User>) => state
)

export const selectUsersByIds = createSelector(
  [(state: EntityState<User>) => state, (_, ids: string[]) => ids],
  (state, ids) => {
    return ids.map((id) => selectUser(state, id)).filter((user) => user !== undefined)
  }
)

const usersSlice = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    setUser: usersEntityAdapter.setOne,
    setUsers: usersEntityAdapter.setMany,
  },
})

export const { setUser, setUsers } = usersSlice.actions
export default usersSlice.reducer
