import { persistor, store } from '@app/flow/store'
import { SplashScreen } from '@components/splash-screen'
import { ThemeProvider } from '@contexts/theme-provider'
import { init as initSentry, BrowserTracing, Replay } from '@sentry/react'
import '@styles/fonts.css'
import { GlobalStyles } from '@styles/global-styles'
import reportWebVitals from '@utils/report-web-vitals'
import React, { lazy, StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import './i18n'

initSentry({
  dsn: process.env.SENTRY_DSN_FLOW,
  integrations: [new BrowserTracing(), new Replay()],
  tracesSampleRate: 0.05,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.APP_ENVIRONMENT,
})

const App = lazy(() => import('./app/app'))

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <GlobalStyles />
          <Suspense fallback={<SplashScreen />}>
            <App />
          </Suspense>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
