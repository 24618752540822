import { LogoHeader } from '@components/logo-header'
import React from 'react'
import { Container, DotsAnimation } from 'ui'

export const SplashScreen = () => {
  return (
    <Container height="100vh" display="flex" flexDirection="column" alignItems="center">
      <LogoHeader />
      <Container display="flex" height="100vh" alignItems="center">
        <DotsAnimation size={120} />
      </Container>
    </Container>
  )
}
