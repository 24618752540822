import { createSelector, createSlice, EntityState } from '@reduxjs/toolkit'
import { isValueDefined } from '@shared/lib/is-value-defined'
import { User } from '../../model/schema'
import { selectUser, usersEntityAdapter, usersInitialState } from '../model/entity-adapter'

export const selectUsersByIds = createSelector(
  [(state: EntityState<User>) => state, (_, ids: string[]) => ids],
  (state, ids) => {
    if (!ids) return []
    return ids.map((id) => selectUser(state, id)).filter(isValueDefined)
  }
)

const usersSlice = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    setUser: usersEntityAdapter.setOne,
    setUsers: usersEntityAdapter.setMany,
  },
})

export const { setUser, setUsers } = usersSlice.actions
export default usersSlice.reducer
