import { PersonalProfile } from '@api/account/types/personal-profile'
import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { Conversation } from './conversation'
import {
  conversationsEntityAdapter,
  getConversationsInitialState,
  selectConversationById,
} from './entity-adapter'
import { parseConversationUserLeft } from './helpers/parse-conversation-user-left'
import { parsePersonalProfile } from './helpers/parse-personal-profile'
import { parseUser } from './helpers/parse-user'
import { SyncConversationsPayload } from './sync-conversations'
import { AddConversationMemberPayload } from './types/add-conversation-member'
import { LeaveConversation } from './types/leave-conversation'
import { UpdateConversation } from './types/update-conversation'

export interface ConversationsState extends EntityState<Conversation> {
  lastConversationId?: string
  lastConversationTimestamp?: string
}

const initialState: ConversationsState = {
  lastConversationId: undefined,
  lastConversationTimestamp: undefined,
  ...getConversationsInitialState(),
}

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    syncConversations: (_state, { payload }: PayloadAction<SyncConversationsPayload>) => {
      const updatedConversations = payload.conversations.map((conversation) => {
        const chatUserIds = conversation.chatUsers.map((chatUser) => chatUser.user.userId)

        return {
          ...conversation,
          chatUserIds,
        }
      })
      return {
        lastChatIdInBatch: payload.lastConversationId,
        lastTimestamp: payload.lastConversationTimestamp,
        ...conversationsEntityAdapter.setAll(getConversationsInitialState(), updatedConversations),
      }
    },
    addConversation: (state, action: PayloadAction<Conversation>) => {
      conversationsEntityAdapter.addOne(state, action.payload)
    },
    updateConversation: (state, action: PayloadAction<UpdateConversation>) => {
      const { id, updatedData } = action.payload
      if (!updatedData) {
        return
      }

      conversationsEntityAdapter.updateOne(state, {
        id,
        changes: updatedData,
      })
    },
    leaveConversation: (state, action: PayloadAction<LeaveConversation>) => {
      const { id, myUserId } = action.payload
      const conversation = selectConversationById(state, id)
      if (!conversation || !myUserId) return
      const updatedMyConversationUser = parseConversationUserLeft(conversation, myUserId)

      if (updatedMyConversationUser) {
        conversationsEntityAdapter.updateOne(state, {
          id,
          changes: {
            chatUserIds: updatedMyConversationUser.chatUserIds,
            chatUsers: updatedMyConversationUser.chatUsers,
          },
        })
      }
    },
    joinConversation: (
      state,
      action: PayloadAction<{ conversation: Conversation; newUser: PersonalProfile }>
    ) => {
      const { conversation, newUser } = action.payload
      if (!conversation || !newUser) {
        return
      }
      const myUser = parsePersonalProfile(newUser)
      const existingConversation = state.entities[conversation.id]

      if (!existingConversation) {
        // Preserve all existing users from the incoming conversation and either update the current user's status or add them as new
        const existingUsers = conversation.chatUsers || []
        const myUserInExistingUsers = existingUsers.find(
          (user) => user.user.userId === newUser.userId
        )

        // If user exists in the list, update their leftChat status, otherwise add him
        const updatedUsers = myUserInExistingUsers
          ? existingUsers.map((user) =>
              user.user.userId === newUser.userId ? { ...user, leftChat: false } : user
            )
          : [...existingUsers, { ...myUser, leftChat: false }]

        conversationsEntityAdapter.addOne(state, {
          ...conversation,
          chatUsers: updatedUsers,
          chatUserIds: updatedUsers.map((user) => user.user.userId),
        })
      } else {
        const myUserInConversation = existingConversation.chatUsers.find(
          (user) => user.user.userId === newUser.userId
        )

        if (myUserInConversation) {
          // If user exists but he left before - mark him as active again, otherwise add him
          myUserInConversation.leftChat = false
        } else {
          existingConversation.chatUsers.push({ ...myUser, leftChat: false })
        }

        const updatedUserIds = existingConversation.chatUsers.map((user) => user.user.userId)

        conversationsEntityAdapter.updateOne(state, {
          id: conversation.id,
          changes: {
            chatUsers: existingConversation.chatUsers,
            chatUserIds: updatedUserIds,
          },
        })
      }
    },
    deleteConversation: (state, action: PayloadAction<{ id: string }>) => {
      conversationsEntityAdapter.removeOne(state, action.payload.id)
    },
    markConversationAsUnread: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      const conversation = selectConversationById(state, id)
      if (!conversation) {
        return
      }
      conversationsEntityAdapter.updateOne(state, {
        id,
        changes: {
          unreadMessagesCount: conversation.unreadMessagesCount + 1,
        },
      })
    },
    markConversationAsRead: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      conversationsEntityAdapter.updateOne(state, {
        id,
        changes: {
          unreadMessagesCount: 0,
        },
      })
    },
    addConversationMember: (state, action: PayloadAction<AddConversationMemberPayload>) => {
      const { id, usersToAdd } = action.payload

      const existingConversation = state.entities[id]

      if (existingConversation) {
        const existingUsers = existingConversation.chatUsers || []

        const newMembers = usersToAdd.map((user) => parseUser(user))

        conversationsEntityAdapter.updateOne(state, {
          id,
          changes: {
            chatUsers: [...existingUsers, ...newMembers],
          },
        })
      }
    },
  },
})

export default conversationsSlice.reducer

export const {
  syncConversations,
  updateConversation,
  addConversation,
  leaveConversation,
  joinConversation,
  deleteConversation,
  markConversationAsRead,
  markConversationAsUnread,
  addConversationMember,
} = conversationsSlice.actions
