import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import createIndexedDBStorage from 'redux-persist-indexeddb-storage'
import draftMessagesReducer from '../../features/messages/draft-message-slice'
import channelsReducer from '../../src/entities/channels/model/slice'
import conversationsReducer from '../../src/entities/conversations/model/slice'
import conversationReducer from '../../src/entities/messages/model/slice'
import onboardingReducer from '../../src/entities/onboarding/model/slice'
import usersReducer from '../../src/entities/teammates/api/model/slice'
import chatsReducer from '../../src/features/chats/slice'
import userSortingReducer from '../../src/features/user-sorting/slice'

const storage = createIndexedDBStorage('flowDB')

const persistConversationsConfig = {
  key: 'conversations',
  storage,
  serialize: false,
}

const persistConversationConfig = {
  key: 'conversation',
  storage,
  serialize: false,
}

const persistedConversationsReducer = persistReducer(
  persistConversationsConfig,
  conversationsReducer
)

const persistedConversationReducer = persistReducer(persistConversationConfig, conversationReducer)

export const store = configureStore({
  reducer: {
    draftMessages: draftMessagesReducer,
    conversation: persistedConversationReducer,
    chats: chatsReducer,
    users: usersReducer,
    conversations: persistedConversationsReducer,
    userSorting: userSortingReducer,
    channels: channelsReducer,
    onboarding: onboardingReducer,
  },
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type
export type AppDispatch = typeof store.dispatch

// Using `redux-persist` for storing data in storage, commented for now as it doesn't seem as a secure solution

// const rootPersistConfig = {
//   key: 'root',
//   storage,
// }

// // Define where should be reducers stored (localStorage, sessionStorage)
// const draftMessagesPersistConfig = {
//   key: 'draftMessages',
//   storage,
// }

// const rootReducer = combineReducers({
//   draftMessages: persistReducer(draftMessagesPersistConfig, draftMessagesReducer),
//   // add more
// })

// const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

// export const store = configureStore({
//   reducer: persistedReducer,
// })
// export const persistor = persistStore(store)

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type
// export type AppDispatch = typeof store.dispatch
